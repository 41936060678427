import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` helps leading agencies develop conversational products by providing timely, actionable insights to its clients. `}<a parentName="p" {...{
        "href": "https://www.reprisedigital.com/"
      }}>{`Reprise Digital`}</a>{` (previously Ansible) is a digital-first agency part of the Mediabrands division of the Interpublic Group (IPG) known for launching conversational products for top brands including Acuvue, Kia, and Zyrtec.`}</p>
    <p>{`Robert Lamberson, Director of Analytics at Reprise Digital, is responsible for summarizing data accurately and succinctly so that his clients and team could identify and use significant data points to make product decisions. Building solutions for an ever-evolving voice-first world is challenging given that it’s a new interface with varying user interactions. Brands want to know how they are going to monetize it and whether or not this interface is worth allocating time and resources to.`}</p>
    <p>{`When measuring and reporting success metrics to clients, Robert remarks that Dashbot is the single source of truth for conversational analytics and emphasizes how easy it is to extract information clients request on-demand. He started using Dashbot when Reprise Digital launched one of its first conversational products, a Facebook Messenger chatbot for Kia Niro. The chatbot saw a tremendous amount of traffic because Kia used it to `}<a parentName="p" {...{
        "href": "https://www.ansibleww.com/case-study/kia-nirobot/"
      }}>{`release a Super Bowl ad featuring its new Niro crossover`}</a>{`, so Robert found Dashbot’s reports and tools easy to understand and use to summarize primary KPIs for Kia.`}</p>
    <p>{`Seeing the promise of conversation and the rise in voice platforms, the team at Reprise Digital designed and built Zyrtec’s `}<a parentName="p" {...{
        "href": "https://www.amazon.com/Johnson-Zyrtec-Your-Daily-AllergyCast/dp/B06XQ71CLR/ref=sr_1_1?ie=UTF8&qid=1535125223&sr=8-1&keywords=zyrtec+allergycast"
      }}>{`Alexa Skill`}</a>{` (2017) and `}<a parentName="p" {...{
        "href": "https://assistant.google.com/services/a/uid/000000ac37fa8196?hl=en-US"
      }}>{`Google Action`}</a>{` (2018). This project was particularly interesting because the only variable was the platform users could connect on. Reprise Digital had the challenge of designing a consistent user experience for a big brand with the same goals across both platforms. In addition to designing an engaging conversational experience, Robert’s team was tasked with measuring and reporting success metrics back to their clients.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/08/29162922/ZyrtecAlexaSkill.png",
        "alt": null
      }}></img></p>
    <p>{`The Zyrtec Alexa Skill and Google Action both provide an allergy forecast which includes a daily allergy impact and pollen count. Users are asked to log symptoms and track how they feel so it can be tied back to the allergy forecast. The team at Reprise Digital saw usage across both platforms differ vastly. Because the Google Assistant does not require a location to set up like Amazon Alexa does, many Google users skipped over providing a location and went straight to logging how they felt. Despite this shift in user experience due to the platform limitations, Google users aren’t reporting how they are feeling as often as Alexa users.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/08/29163009/ZyrtecGoogleAction.png",
        "alt": null
      }}></img></p>
    <p>{`Since the Alexa Skill was developed for allergy season 2017, Reprise Digital based this year’s benchmarks for the new Google Action off of the historical performance from the Alexa Skill. Zyrtec saw an 18% retention rate on Amazon last year and one of our benchmarks was to match that across both voice platforms. Reprise Digital is excited to report that the Google Action continues to see a vastly growing user base and is meeting benchmark for repeat usage.`}</p>
    <p>{`A notable anecdote was that in March 2018, the Google Assistant redirected any user who asked, “What’s the weather like today?” to the Zyrtec Google Action. Robert saw a huge spike in traffic and wanted to investigate the source of all of these new users. He used Dashbot’s Message Funnel to dig deeper into this influx of interactions and saw all of these irrelevant weather queries. He and his team then scrubbed the users who did not voluntarily launch the Zyrtec Google Action in order to provide their client with more relevant data.`}</p>
    <p>{`The team at Reprise Digital is excited to continue helping big brands unravel the potential of voice and reach consumers in a new way. Dashbot looks forward to working closely with top agencies to grow and optimize their brands’ conversational products. Try out Zyrtec’s voice applications by saying, “Alexa, start Zyrtec,” or ask your Google Assistant to give you your AllergyCast.`}</p>
    <p><strong parentName="p">{`ABOUT DASHBOT`}</strong></p>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.dashbot.io/"
        }}>{`Dashbot`}</a>{` is a conversational analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide bot and voice specific metrics including sentiment analysis, conversational analytics, and full transcripts. We also have tools to take action on the data, like our live-person takeover, broadcast messaging, and goal funnels.`}</em></p>
    <p><em parentName="p">{`Learn more about Dashbot’s analytics and tools and sign up for free: `}<a parentName="em" {...{
          "href": "https://www.dashbot.io/"
        }}>{`www.dashbot.io`}</a>{`.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales/?ref=blog+zyrtec+case+study"
      }}><br parentName="a"></br>
        {`Contact us for a demo `}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      